import { Dispatch, SetStateAction, useContext, useState } from "react";
import { useFlags } from "launchdarkly-react-client-sdk";

import { EventType } from "shared/api/api";
import { CustomSignalEventDefinition } from "shared/api/customSignalEvents/api";
import { EventRegistryContext } from "shared/contexts/EventRegistryContext";
import { useFilterSchemaForType } from "shared/hooks";
import { EventTypeEnum } from "shared/types";

import {
  FilterGroupState,
  FilterRowState,
} from "features/ui/Filters/FilterBuilder/types";
import {
  filterBuilderQueryToFilterBuilderState,
  removeAttributesFromFilterGroupState,
} from "features/ui/Filters/FilterBuilder/utils";
import { Option, SelectOption } from "features/ui/Select";
import { SchemaEntry } from "features/ui/Table";
import { DataType } from "features/ui/Table/TableBodyCell";

import { DEFAULT_EDIT_MODE } from "./constants";
import { CustomAttribute } from "./Form/CustomAttributes/CustomAttributeSelect";
import { Downsampling } from "./Form/DownsamplingSection";
import {
  isCustomAttributeComplete,
  validateCustomEventFormState,
} from "./utils";

export const useEventTypeOptions = (
  ignoreTypes: EventTypeEnum[] = [],
  requiredAttribute?: string
): SelectOption[] => {
  const eventTypes = useContext(EventRegistryContext);

  return (
    eventTypes
      ?.filter((eventType) => !ignoreTypes.includes(eventType.type))
      // we want to offer only event types that have certain properties like engineHoursAttribute
      ?.filter(
        (eventType) =>
          !requiredAttribute || eventType[requiredAttribute as keyof EventType]
      )
      ?.map((eventType) => ({
        id: eventType.type,
        value: eventType.name,
        testId: eventType.type,
      })) || []
  );
};

export const usePlaygroundFormState = (
  customSignalEventDefinition?: CustomSignalEventDefinition
): SingnalEventStudioState => {
  const { customSignalEvents } = useFlags();

  const [name, setName] = useState(customSignalEventDefinition?.name || "");

  const [description, setDescription] = useState(
    customSignalEventDefinition?.description || ""
  );

  const [id, setId] = useState(customSignalEventDefinition?.ID || "");

  const [isIdSet, setIsIdSet] = useState(!!customSignalEventDefinition);

  const [editMode, setEditMode] = useState(DEFAULT_EDIT_MODE);

  const eventTypeOptions = useEventTypeOptions([
    EventTypeEnum.INSPECTION,
    EventTypeEnum.REPAIR,
  ]);
  const [selectedEventTypeOption, setSelectedEventTypeOption] = useState<
    SelectOption | undefined
  >(
    eventTypeOptions.find(
      ({ id }) =>
        id === customSignalEventDefinition?.eventDefinition?.inputEventType
    )
  );

  const schema = useFilterSchemaForType(
    selectedEventTypeOption?.id as EventTypeEnum | undefined
  );

  const [filterBuilderState, setFilterBuilderState] = useState<
    FilterGroupState | undefined
  >(
    filterBuilderQueryToFilterBuilderState(
      customSignalEventDefinition?.eventDefinition?.inputEventFilter
    )
  );

  const [
    customAttributeFilterBuilderState,
    setCustomAttributeFilterBuilderState,
  ] = useState<FilterGroupState | undefined>(
    filterBuilderQueryToFilterBuilderState(
      customSignalEventDefinition?.eventDefinition?.customAttributeFilter
    )
  );

  const onCustomAttributeRemoved = (id: string) => {
    if (customAttributeFilterBuilderState) {
      setCustomAttributeFilterBuilderState(
        removeAttributesFromFilterGroupState(
          customAttributeFilterBuilderState,
          [id]
        )
      );
    }
  };

  const selectedDownsampling =
    customSignalEventDefinition?.eventDefinition?.downsampling;

  const [selectedDownsamplingOption, setSelectedDownsamplingOption] =
    useState<Downsampling>({
      option: selectedDownsampling?.type || "none",
      secondsAfter: selectedDownsampling?.parameters?.after || 0,
      secondsBefore: selectedDownsampling?.parameters?.before || 0,
      occurrences: selectedDownsampling?.parameters?.occurrences || 0,
    });

  const [selectedVIN, setSelectedVIN] = useState<string>();

  const [customAttributes, setCustomAttributes] = useState<CustomAttribute[]>(
    customSignalEventDefinition?.eventDefinition?.customAttributes.map(
      ({ ID, parameters, ...otherCAData }, index) => {
        const { eventFilter, ...otherParameters } = parameters;
        return {
          index,
          id: ID,
          ...otherCAData,
          ...otherParameters,
          eventFilter: filterBuilderQueryToFilterBuilderState(
            parameters?.eventFilter
          ),
        };
      }
    ) || []
  );

  const completeCustomAttributes = customAttributes.filter(
    isCustomAttributeComplete
  );

  const customAttributesSchema: SchemaEntry[] = completeCustomAttributes.map(
    (attribute) => ({
      label: attribute.label,
      accessor: attribute.id,
      dataType: "number" as DataType,
      filter: {
        label: attribute.label,
        fieldName: attribute.id,
        filterType: "number",
        disableSelectFilters: true,
      },
    })
  );

  const formValidationErrors = validateCustomEventFormState(
    name,
    id,
    selectedEventTypeOption,
    filterBuilderState,
    customAttributeFilterBuilderState,
    customAttributes,
    selectedDownsamplingOption,
    customSignalEvents
  );

  const isValidForm = formValidationErrors.length === 0;

  const showPreview = isValidForm && selectedVIN && !editMode;

  return {
    name,
    setName,
    description,
    setDescription,
    id,
    setId,
    isIdSet,
    setIsIdSet,
    editMode,
    setEditMode,
    eventTypeOptions,
    selectedEventTypeOption,
    setSelectedEventTypeOption,
    schema,
    filterBuilderState,
    setFilterBuilderState,
    customAttributeFilterBuilderState,
    setCustomAttributeFilterBuilderState,
    onCustomAttributeRemoved,
    selectedDownsamplingOption,
    setSelectedDownsamplingOption,
    customAttributes,
    setCustomAttributes,
    completeCustomAttributes,
    customAttributesSchema,
    formValidationErrors,
    isValidForm,
    showPreview,
    selectedVIN,
    setSelectedVIN,
  };
};

export interface SingnalEventStudioState {
  name: string;
  setName: Dispatch<SetStateAction<string>>;
  description: string;
  setDescription: Dispatch<SetStateAction<string>>;
  id: string;
  setId: Dispatch<SetStateAction<string>>;
  isIdSet: boolean;
  setIsIdSet: Dispatch<SetStateAction<boolean>>;
  editMode: boolean;
  setEditMode: Dispatch<SetStateAction<boolean>>;
  eventTypeOptions: SelectOption[];
  selectedEventTypeOption?: SelectOption<Option>;
  setSelectedEventTypeOption: Dispatch<
    SetStateAction<SelectOption<Option> | undefined>
  >;
  schema?: SchemaEntry<string>[];
  filterBuilderState?: FilterGroupState<FilterRowState>;
  setFilterBuilderState: Dispatch<
    SetStateAction<FilterGroupState<FilterRowState> | undefined>
  >;
  customAttributeFilterBuilderState?: FilterGroupState<FilterRowState>;
  setCustomAttributeFilterBuilderState: Dispatch<
    SetStateAction<FilterGroupState<FilterRowState> | undefined>
  >;
  onCustomAttributeRemoved: (id: string) => void;
  selectedDownsamplingOption: Downsampling;
  setSelectedDownsamplingOption: Dispatch<SetStateAction<Downsampling>>;
  customAttributes: CustomAttribute[];
  setCustomAttributes: Dispatch<SetStateAction<CustomAttribute[]>>;
  completeCustomAttributes: CustomAttribute[];
  customAttributesSchema: SchemaEntry<string>[];
  formValidationErrors: string[];
  isValidForm: boolean;
  showPreview?: string | boolean;
  selectedVIN?: string;
  setSelectedVIN: Dispatch<SetStateAction<string | undefined>>;
}
