import { AppConfig, AuthScopes, PagesConfig } from "config/config";

import pagesConfig from "./pagesConfig.json";

export const tenant = "knightswift";
export const host = `${tenant}.cloud.viaduct.ai`;

const prodAuthConfig = {
  name: "Night Swift",
  idp: "0oa13z7dyOysdGFnU5d6",
  clientId: "0oajos4qu98OuSBRA5d7",
  issuer: "https://viaduct-cloud.okta.com/oauth2/ausjos4lmboS8svCo5d7",
  scopes: ["openid", "email", "offline_access"] as AuthScopes[],
};

const prodAPIConfig = {
  url: `https://api.${host}/v0`,
};

const featureFlagsProd = {
  launchDarklyClientId: "66de8e9b73febb0f0b324c91",
};

export const prodAppConfig: AppConfig = {
  environment: "prod",
  pages: pagesConfig as PagesConfig,
  analytics: {
    heap: { id: "2946143066" },
  },
  featureFlags: featureFlagsProd,
  auth: prodAuthConfig,
  api: prodAPIConfig,
};
