import { useContext } from "react";
import classNames from "classnames";
import { HiChevronDown as ChevronDown } from "react-icons/hi";

import { APIFilter } from "shared/api/utils";
import { Sort } from "shared/types";

import { AppLayoutStateContext } from "features/layout/AppLayout";
import Checkbox from "features/ui/Checkbox";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import { onFilterChangeCallback } from "features/ui/Filters/types";
import { SchemaEntry } from "features/ui/Table";
import { RightBorderForStickyCell } from "features/ui/Table/TableBodyCell/TableBodyCell";
import Tooltip from "features/ui/Tooltip";

import styles from "./TableHeaderCell.module.css";
import TableHeaderFilter from "./TableHeaderFilter";

export interface TableHeaderCellProps {
  schemaEntry: SchemaEntry;
  idx: number;
  sort?: Sort;
  pageKey?: string;
  dense?: boolean;
  activeFilters?: FilterGroupState;
  staticFilters?: APIFilter[]; // if current filter is part of staticFilters then the filter will not be displayed
  onSort?: () => void;
  filtersInitialized?: boolean;
  onFiltersReset?: (fieldNames?: string[]) => void;
  onFilterChange?: onFilterChangeCallback;
  stickyFirstColumn?: boolean;
  limitedWidthClass?: string;
}

const TableHeaderCell = ({
  schemaEntry,
  idx,
  onSort,
  sort,
  pageKey,
  activeFilters,
  staticFilters,
  filtersInitialized,
  onFiltersReset,
  onFilterChange,
  dense = false,
  stickyFirstColumn,
  limitedWidthClass,
}: TableHeaderCellProps) => {
  const {
    label,
    accessor,
    dataType,
    description,
    sortable = false,
    filter,
    hideFilter,
    align = "left",
    toggleable = false,
    selectable,
  } = schemaEntry;

  const isSticky = Boolean(stickyFirstColumn && idx === 0);
  const appLayoutState = useContext(AppLayoutStateContext);

  const className = classNames(styles.hdr, styles[dataType], {
    [styles.toggleable]: toggleable,
    toggleable,
    [styles.dense]: dense,
    "sticky left-0 bg-white z-10": isSticky,
    "z-[2]": !appLayoutState?.defaultExpandedState && appLayoutState?.expanded,
  });

  const containerClasses = classNames(styles.container, {
    [styles.alignRight]: align === "right",
    [styles.alignCenter]: align === "center",
  });

  const isInStaticFilters = Boolean(
    staticFilters?.some(({ name }) => name === accessor)
  );

  const showTableFilter = !isInStaticFilters && !hideFilter;

  return (
    <th scope="col" className={classNames(className, limitedWidthClass)}>
      <div className={containerClasses}>
        {selectable && (
          <Checkbox
            label={""}
            checked={selectable.checked}
            onChange={selectable.onClick}
            indeterminate={selectable.indeterminate}
            testId={`table-header-cell-checkbox-${accessor}`}
          />
        )}

        <Tooltip content={description} testId="thc-tooltip">
          <span className={styles.label}>{label}</span>
        </Tooltip>

        {sortable && (
          <div
            onClick={() => sortable && onSort && onSort()}
            className={styles.sortIconWrap}
            title={`Order by ${label}`}
            data-testid={`order-${accessor}`}
          >
            <ChevronDown
              className={classNames("transform rotate-180", {
                "text-blue-500": sort === "asc",
              })}
            />
            <ChevronDown
              className={classNames({
                "text-blue-500": sort === "desc",
              })}
            />
          </div>
        )}
        {showTableFilter && (
          <TableHeaderFilter
            accessor={accessor}
            filter={filter}
            activeFilters={activeFilters}
            staticFilters={staticFilters}
            onFiltersReset={onFiltersReset}
            onFilterChange={onFilterChange}
            filtersInitialized={filtersInitialized}
            // filters inside sticky column do not work as there is problem when there are few items
            // whenever we have a sticky column we display filters with fixed position
            fixed={isSticky}
          />
        )}
      </div>
      <RightBorderForStickyCell isSticky={isSticky} colorClass="bg-gray-100" />
    </th>
  );
};

export default TableHeaderCell;
