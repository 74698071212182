import { DEFAULT_OPERATOR_PER_TYPE } from "features/ui/Filters/constants";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import {
  FilterOperator,
  FilterType,
  RelatesFilterState,
} from "features/ui/Filters/types";

export const FILTER_TYPE: FilterType = "relates";
export const SIGNAL_EVENT_ID_FIELD_NAME = "signalEventID";

export const MIN_WINDOW_SIZE = 1;
export const MAX_WINDOW_SIZE = 365;

const DEFAULT_FILTERS_FOR_OPERATOR = (
  filterOperator?: FilterOperator
): FilterGroupState => ({
  id: "group-0",
  type: "group",
  anyAll: "all",
  children: [
    {
      id: "row-0",
      type: "row",
      attribute: SIGNAL_EVENT_ID_FIELD_NAME,
      operator: filterOperator || DEFAULT_OPERATOR_PER_TYPE.relates,
      values: [],
    },
  ],
});

export const DEFAULT_RELATES_FILTER: RelatesFilterState = {
  operator: "occurs",
  options: {
    windowSize: "30",
    windowDirection: "before",
    windowType: "days",
  },
  filters: DEFAULT_FILTERS_FOR_OPERATOR(),
};

export const DEFAULT_RELATES_FILTER_FOR_OPERATOR = (
  filterOperator: FilterOperator
): RelatesFilterState => ({
  operator: "occurs",
  options: {
    windowSize: "30",
    windowDirection: "before",
    windowType: "days",
  },
  filters: DEFAULT_FILTERS_FOR_OPERATOR(filterOperator),
});
