import { useMemo } from "react";
import Skeleton from "react-loading-skeleton";

import { GetSuggestedIssuesOverviewRequest } from "shared/api/issues/api";
import { useSuggestedIssuesOverview } from "shared/api/issues/hooks";
import { APIFilter } from "shared/api/utils";
import { useCustomLocalStorageState } from "shared/hooks";
import {
  BucketBySuggestedIssuesEnum,
  GroupBySuggestedIssuesEnum,
} from "shared/types";

import { useBarSelection } from "pages/ClaimAnalytics/tabPages/TopContributors/hooks";

import Accordion from "features/ui/Accordion";
import APIError from "features/ui/APIError";
import BarChart from "features/ui/charts/BarChart";
import ChartActions, {
  ChartAction,
  SelectedChartOptions,
} from "features/ui/charts/ChartActions";
import { ChartActionsWrap } from "features/ui/charts/ChartActionsWrap";
import { getDefaultActions } from "features/ui/charts/utils";
import { getFiltersQuery } from "features/ui/Filters/FilterBuilder/utils";
import { UseFilterSortState } from "features/ui/Filters/types";
import { SelectOption } from "features/ui/Select";

import {
  DASHBOARD_GROUP_BY_KEY,
  SUGGESTED_ISSUES_CHART_ACTIONS,
  SUGGESTED_ISSUES_CHART_ACTIONS_KEY,
  SUGGESTED_ISSUES_CHART_KEY,
  SUGGESTED_ISSUES_DASHBOARD_KEY,
} from "./constants";
import {
  disabledIds,
  getAxisKeyLabelFromActions,
  getGroupByAttribute,
  transformSuggestedIssuesOverviewData,
} from "./utils";

interface Props {
  filterSortState: UseFilterSortState;
  staticFilters?: APIFilter[];
}

const SuggestedIssuesDashboard = ({
  filterSortState,
  staticFilters,
}: Props) => {
  const [
    suggestedIssuesDashboardExtended,
    setSuggestedIssuesDashboardExtended,
  ] = useCustomLocalStorageState(SUGGESTED_ISSUES_DASHBOARD_KEY, {
    defaultValue: false,
  });

  const [selectedOptions, setSelectedOptions] = useCustomLocalStorageState<
    SelectedChartOptions[]
  >(SUGGESTED_ISSUES_CHART_KEY, {
    defaultValue: getDefaultActions(SUGGESTED_ISSUES_CHART_ACTIONS),
  });

  const { filters, resetFilterSortState, manageOnFilterChange } =
    filterSortState;
  const filtersQuery = getFiltersQuery(filters, staticFilters);

  const { axisKey: axisKeyBucketBy, axisValue: axisValueBucketBy } =
    getAxisKeyLabelFromActions(
      selectedOptions,
      SUGGESTED_ISSUES_CHART_ACTIONS,
      "bucketBy"
    );

  const { axisKey: axisKeyGroupBy, axisValue: axisValueGroupBy } =
    getAxisKeyLabelFromActions(
      selectedOptions,
      SUGGESTED_ISSUES_CHART_ACTIONS,
      "groupBy"
    );

  const selectedGroupByAttribute: SelectOption = useMemo(
    () => getGroupByAttribute(axisKeyGroupBy, axisValueGroupBy),
    [axisKeyGroupBy, axisValueGroupBy]
  );

  const {
    selectedBars,
    showContextMenu,
    handleOnChartClick,
    handleOnBarClick,
    onBarRightClick,
    contextMenu,
  } = useBarSelection({
    selectedGroupByAttribute,
    groupByKey: DASHBOARD_GROUP_BY_KEY,
    onIssuesFiltersChange: disabledIds.includes(axisKeyGroupBy)
      ? undefined
      : manageOnFilterChange,
    menuOffsetElement: document.querySelector(
      '[data-testid="accordion-suggestedIssuesDashboard"]'
    ),
  });

  const requestParams: GetSuggestedIssuesOverviewRequest = {
    bucketBy: axisKeyBucketBy as BucketBySuggestedIssuesEnum,
    filter: filtersQuery,
    groupBy: axisKeyGroupBy as GroupBySuggestedIssuesEnum,
  };

  const { axisValue: measureLabel } = getAxisKeyLabelFromActions(
    selectedOptions,
    SUGGESTED_ISSUES_CHART_ACTIONS,
    "measure"
  );

  const { data, isLoading, error } = useSuggestedIssuesOverview(requestParams);

  const { graphData, yAxisBars } = transformSuggestedIssuesOverviewData(
    data,
    axisKeyBucketBy as BucketBySuggestedIssuesEnum,
    axisKeyGroupBy as GroupBySuggestedIssuesEnum
  );

  const [availableChartActions, setAvailableChartActions] =
    useCustomLocalStorageState<ChartAction[]>(
      SUGGESTED_ISSUES_CHART_ACTIONS_KEY,
      {
        defaultValue: SUGGESTED_ISSUES_CHART_ACTIONS,
      }
    );

  const handleOnOptionChange = (selectedOptions: SelectedChartOptions[]) => {
    // we want to make sure that user cannot select the same option for both groupBy and bucketBy which returns an API error
    const groupByOption = selectedOptions.find(
      (option) => option.id === "groupBy"
    )?.optionId;
    const bucketByOption = selectedOptions.find(
      (option) => option.id === "bucketBy"
    )?.optionId;

    // update chart actions to remove the selected bucketBy option from groupBy options and vice versa
    const updatedChartActions = SUGGESTED_ISSUES_CHART_ACTIONS.map((action) => {
      if (action.id === "groupBy" && bucketByOption) {
        return {
          ...action,
          options: action.options?.filter(
            (option) => option.id !== bucketByOption
          ),
        };
      }
      if (action.id === "bucketBy" && groupByOption) {
        return {
          ...action,
          options: action.options?.filter(
            (option) => option.id !== groupByOption
          ),
        };
      }
      return action;
    });

    setAvailableChartActions(updatedChartActions);
    setSelectedOptions(selectedOptions);
  };

  const baseChartTitle = `${measureLabel} grouped by ${axisValueGroupBy}`;
  const chartTitle =
    axisKeyBucketBy === "none"
      ? baseChartTitle
      : `${baseChartTitle} and bucketed by ${axisValueBucketBy}`;

  return (
    <Accordion
      id="suggestedIssuesDashboard"
      title={chartTitle}
      expanded={suggestedIssuesDashboardExtended}
      onChange={() =>
        setSuggestedIssuesDashboardExtended(!suggestedIssuesDashboardExtended)
      }
      arrowPosition="left"
      rightContent={
        <ChartActionsWrap>
          <ChartActions
            actions={availableChartActions}
            selectedOptions={selectedOptions}
            onOptionChange={handleOnOptionChange}
          />
        </ChartActionsWrap>
      }
    >
      <div>
        {showContextMenu && contextMenu}
        {!error && (
          <div>
            {isLoading && <Skeleton height={300} />}
            {graphData && graphData.length > 0 ? (
              <div>
                <BarChart
                  data={graphData}
                  xAxisKey={DASHBOARD_GROUP_BY_KEY}
                  xAxisProps={{
                    angle: -10,
                    height: 50,
                    dy: 10,
                  }}
                  yAxisBars={yAxisBars}
                  selectedBars={selectedBars}
                  onBarClick={handleOnBarClick}
                  onBarRightClick={onBarRightClick}
                  onChartClick={handleOnChartClick}
                />
              </div>
            ) : (
              <div className="py-4 text-gray-400 text-sm">No data.</div>
            )}
          </div>
        )}
        {error && (
          <APIError
            error={error}
            onBadRequest={() => {
              resetFilterSortState();
              setSelectedOptions(
                getDefaultActions(SUGGESTED_ISSUES_CHART_ACTIONS)
              );
            }}
          />
        )}
      </div>
    </Accordion>
  );
};

export default SuggestedIssuesDashboard;
