import { createContext, useEffect, useState } from "react";
import classNames from "classnames";
import { useFlags } from "launchdarkly-react-client-sdk";

import { useWindowSize } from "shared/hooks";

import ErrorBoundary from "features/layout/ErrorBoundary";
import Feedback from "features/layout/Feedback/Feedback";
import SidebarNav from "features/layout/SidebarNav";
import Breadcrumbs from "features/ui/Breadcrumbs";

import { routesBreadcrumbs } from "services/breadcrumbs";

interface AppLayoutProps {
  wrapperClasses?: string;
  children: React.ReactElement;
}

interface AppLayoutStateContextModel {
  defaultExpandedState: boolean;
  expanded: boolean;
}

const DEFAULT_WRAPPER_CLASSES = "pb-10 px-8 pt-6";
const DEFAULT_EXPANDED_SIDE_NAV = true;
const EXPAND_BREAKPOINT_PX = 1024;

export const AppLayoutStateContext = createContext<
  AppLayoutStateContextModel | undefined
>(undefined);

const AppLayout = ({
  children,
  wrapperClasses = DEFAULT_WRAPPER_CLASSES,
}: AppLayoutProps) => {
  const { feedbackForm } = useFlags();

  const [expanded, setExpanded] = useState(true);
  const { width } = useWindowSize();
  const [defaultExpandedState, setDefaultExpandedState] = useState(
    DEFAULT_EXPANDED_SIDE_NAV
  );

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  /**
   * When screen resize happens, automatically expand/close side nav
   */
  useEffect(() => {
    const isDefaultExpandedState = width
      ? Boolean(width >= EXPAND_BREAKPOINT_PX)
      : DEFAULT_EXPANDED_SIDE_NAV;

    setDefaultExpandedState(isDefaultExpandedState);
    setExpanded(isDefaultExpandedState);
  }, [width]);

  return (
    <AppLayoutStateContext.Provider
      value={{ defaultExpandedState: defaultExpandedState, expanded: expanded }}
    >
      <div className="flex flex-no-wrap min-h-screen">
        <SidebarNav
          expanded={expanded}
          setExpanded={setExpanded}
          toggleExpand={toggleExpand}
        />
        <ErrorBoundary>
          <div
            className={classNames(
              "overflow-x-clip",
              "w-full",
              { "lg:w-[calc(100%-16rem)]": expanded },
              { "lg:w-[calc(100%-1rem)]": !expanded }
            )}
          >
            <div className={classNames("w-full h-full", wrapperClasses)}>
              <Breadcrumbs routeConfig={routesBreadcrumbs} />
              {children}
            </div>
            {feedbackForm && <Feedback />}
          </div>
        </ErrorBoundary>
      </div>
    </AppLayoutStateContext.Provider>
  );
};

export default AppLayout;
