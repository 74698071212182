import { cloneObject } from "shared/utils";

import { FilterRowState } from "features/ui/Filters/FilterBuilder/types";
import { DEFAULT_RELATES_FILTER_FOR_OPERATOR } from "features/ui/Filters/FilterTypes/RelatesFilter/constants";
import RelatesTimeWindowForm from "features/ui/Filters/FilterTypes/RelatesFilter/RelatesFilterForm/RelatesTimeWindowForm";
import { FilterOperator, RelatesFilterState } from "features/ui/Filters/types";
import { getFirstAvailableSelectedOperator } from "features/ui/Filters/utils";
import { SchemaEntry } from "features/ui/Table";

import { OnRowDataChangeHandle } from "./FilterRow";
import OperatorSelect from "./OperatorSelect";
import ValuesSelect from "./ValuesSelect";

interface Props extends FilterRowState {
  onRowDataChange: OnRowDataChangeHandle;
  attributeSchema?: SchemaEntry;
  baseEntityText?: string;
  disabled?: boolean;
}

const DEFAULT_BASE_ENTITY_TEXT = "Claims";

const RelatesFilterFields = ({
  id,
  attribute,
  onRowDataChange,
  attributeSchema,
  values,
  relates,
  disabled,
}: Props) => {
  const defaultFilterOperator = getFirstAvailableSelectedOperator(
    null,
    "relates",
    attributeSchema
  );

  const relatesFilterState: RelatesFilterState =
    relates || DEFAULT_RELATES_FILTER_FOR_OPERATOR(defaultFilterOperator);

  const relatesFilters = relatesFilterState.filters.children;
  const relatesOperator = getFirstAvailableSelectedOperator(
    relatesFilters?.length
      ? (relatesFilters[0] as FilterRowState).operator
      : null,
    "relates",
    attributeSchema
  );

  const relatedSignalEventIDs = relatesFilters?.length
    ? (relatesFilters[0] as FilterRowState).values
    : values;

  const onOperatorChange = (selected: FilterOperator) => {
    const newRelatesFilterState = cloneObject(
      DEFAULT_RELATES_FILTER_FOR_OPERATOR(defaultFilterOperator)
    );
    (newRelatesFilterState.filters.children?.[0] as FilterRowState).operator =
      selected;
    onRowDataChange(id, "relates", newRelatesFilterState);
  };

  const onRelatedSignalEventsChange = (signalEventIDs: string[]) => {
    const newRelatesFilterState = cloneObject(relatesFilterState);
    (newRelatesFilterState.filters.children?.[0] as FilterRowState).values =
      signalEventIDs;
    onRowDataChange(id, "relates", newRelatesFilterState);
  };

  const onTimeWindowUpdate = (newRelatesState: RelatesFilterState) => {
    onRowDataChange(id, "relates", newRelatesState);
  };

  const baseEntityText =
    attributeSchema?.filter?.baseEntityText ?? DEFAULT_BASE_ENTITY_TEXT;

  const showTimeWindowForm = [
    FilterOperator.IN,
    FilterOperator.NOT_IN,
    FilterOperator.STARTS_WITH,
    FilterOperator.IS_NOT_EMPTY,
  ].includes(relatesOperator as FilterOperator);

  return (
    <>
      {attribute && (
        <OperatorSelect
          selected={relatesOperator}
          onChange={onOperatorChange}
          attributeSchema={attributeSchema}
          disabled={disabled}
        />
      )}
      <div className="flex-1">
        <ValuesSelect
          attribute={attribute}
          operator={relatesOperator}
          values={relatedSignalEventIDs}
          schema={attributeSchema}
          onChange={onRelatedSignalEventsChange}
          disabled={disabled}
        />
        {showTimeWindowForm && (
          <div className="leading-10 mt-1">
            <RelatesTimeWindowForm
              relatesState={relatesFilterState}
              onUpdate={onTimeWindowUpdate}
              baseEntityText={baseEntityText}
              inFilterSelector={true}
              disabled={disabled}
              disableSelectingWindowDirection={
                attributeSchema?.filter?.disableSelectingWindowDirection
              }
            />
          </div>
        )}
      </div>
    </>
  );
};

export default RelatesFilterFields;
