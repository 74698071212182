import { useFlags } from "launchdarkly-react-client-sdk";

import { APIFilter } from "shared/api/utils";

import { useFilterSortState } from "features/ui/Filters/hooks";

import {
  DEFAULT_SUGGESTED_ISSUES_FILTER,
  DEFAULT_SUGGESTED_ISSUES_SORT,
  SUGGESTED_ISSUES_PAGE_KEY,
} from "./constants";
import SuggestedIssuesFilters from "./SuggestedIssues/SuggestedIssuesFilters";
import SuggestedIssuesTable from "./SuggestedIssues/SuggestedIssuesTable";
import SuggestedIssuesDashboard from "./SuggestedIssuesDashboard";

const SuggestedIssuesPage = () => {
  const { issueDashboards, issuePublishing } = useFlags();

  const suggestedIssuesFilterSortState = useFilterSortState({
    pageKey: SUGGESTED_ISSUES_PAGE_KEY,
    defaultSort: DEFAULT_SUGGESTED_ISSUES_SORT,
    defaultFilterValues: DEFAULT_SUGGESTED_ISSUES_FILTER,
  });

  const staticFiltersConditional: (APIFilter | boolean)[] = [
    // Users that are not allowed to publish/unpublish suggested issues should see only the published ones
    !issuePublishing && {
      name: "published",
      value: true,
      op: "eq",
    },
  ];
  const staticFilters = staticFiltersConditional.filter(Boolean) as APIFilter[];

  return (
    <>
      <SuggestedIssuesFilters
        filterSortState={suggestedIssuesFilterSortState}
      />
      {issueDashboards && (
        <SuggestedIssuesDashboard
          filterSortState={suggestedIssuesFilterSortState}
          staticFilters={staticFilters}
        />
      )}
      <SuggestedIssuesTable
        topFilterSortState={suggestedIssuesFilterSortState}
        staticFilters={staticFilters}
      />
    </>
  );
};

export default SuggestedIssuesPage;
